import React, { useEffect, useMemo, useState } from "react";
import { Field } from "formik";

import { client } from "api";
import { getBundles } from "api/bundles";
import { getClinic } from "api/clinics";
import { getFacilityTypes } from "api/facilityTypes";
import { getInsuranceProviders } from "api/insuranceProvders";
import { getPaymentTypes } from "api/paymentTypes";

import { Row } from "components/layout";
import { TextField } from "components/ui/new/TextField";
import { MultiSelect } from "components/ui/new/MultiSelect";
import { Select } from "components/ui/new/Select";
import { Checkbox } from "components/ui/new/Checkbox";
import { MenuItem } from "@material-ui/core";

import provinceList from "../provinceList.json";

const mapToOptions = (data, label) =>
  data.map((item) => ({
    id: item.id,
    label: item[label],
  }));

const FIELD_TOOLTIPS = {
  has_location:
    "determines whether or not a clinic shows up on the map and nothing else",
  enabled:
    "when set to 0, prevents the listing from showing up anywhere on the site",
  details_disabled: "prevents the FDP from showing up when set to 1",
};

const HIDDEN_FIELDS_ON_CREATE = [
  "id",
  "slug",
  "city_slug",
  "lat",
  "lng",
  "filter",
  "website",
  "family_doctor_catchment",
  "user_id",
  "timezone",
  "created_at",
  "updated_at",
  "enabled_at",
  "onboarded_at",
];

const multiSelectFields = [
  "payment_types",
  "direct_billing_providers",
  "extra_locations",
  "insurance_providers",
];

const selectFields = [
  "facility_type",
  "bundle_id",
  "price_variant_id",
  "wait_time_for",
  "secondary_type",
  "visibility",
  "region",
];
const selectFieldOrderMap = selectFields.reduce((acc, key, i) => {
  acc[key] = i + 1;
  return acc;
}, {});

const textAreaFields = [
  "request_appointment_info",
  "initial_consultation_price_note",
  "additional_note",
];

const toggleFields = [
  "has_location",
  "onboarded",
  "is_legacy",
  "enabled",
  "details_disabled",
  "publish_wait_time",
  "parent_pays_subscription",
  "parent_pays_leads",
  "service_in_person",
  "service_by_phone",
  "service_by_video",
  "service_at_home",
  "my_virtual_clinic_enabled",
  "medimap_referrals_enabled",
  "online_checkin_enabled",
  "checkins_disabled",
  "lead_generation_disabled",
  "in_person_enabled",
  "phone_enabled",
  "video_enabled",
  "affiliate_links_enabled",
  "call_button_enabled",
  "display_phone_number",
  "initial_consultation_shifts_restricted",
  "hours_same_as_operating",
  "rating_count_estimated",
  "private_services_enabled",
];
const toggleFieldOrderMap = toggleFields.reduce((acc, key, i) => {
  acc[key] = i + 1;
  return acc;
}, {});

const textFieldOrder = [
  "facility_id",
  "name",
  "phone",
  "email",
  "fax",
  "address",
  "address_unit",
  "city",
  "post_code",
  "country",
  "visibility",
  "contact",
  "stripe_customer_id",
];
const textFieldOrderMap = textFieldOrder.reduce((acc, key, i) => {
  acc[key] = i + 1;
  return acc;
}, {});

const fetchFacilityDropdownOptions = async () => {
  const [
    insuranceProviderOptions,
    paymentTypeOptions,
    facilityTypeOptions,
    featureBundlesOptions,
  ] = await Promise.all([
    getInsuranceProviders(),
    getPaymentTypes(),
    getFacilityTypes(),
    getBundles(),
  ]);
  return {
    insuranceProviderOptions,
    paymentTypeOptions,
    facilityTypeOptions,
    featureBundlesOptions,
  };
};

const parseFacilityDropdownOptions = (options, values) => {
  const {
    insuranceProviderOptions,
    paymentTypeOptions,
    facilityTypeOptions,
    featureBundlesOptions,
  } = options;
  return {
    direct_billing_providers: mapToOptions(insuranceProviderOptions, "name"),
    payment_types: mapToOptions(paymentTypeOptions, "type"),
    facility_type: mapToOptions(facilityTypeOptions, "name"),
    bundle_id: mapToOptions(featureBundlesOptions, "id"),
    price_variant_id:
      featureBundlesOptions
        .find((bundle) => bundle.id === values?.bundle_id)
        ?.priceVariants?.map((variantId) => ({
          id: variantId,
          label: variantId,
        })) || [],
    extra_locations: [
      "CA-AB",
      "CA-BC",
      "CA-MB",
      "CA-NB",
      "CA-NL",
      "CA-NS",
      "CA-ON",
      "CA-PE",
      "CA-QC",
      "CA-SK",
      "CA-NT",
      "CA-NU",
      "CA-YK",
    ].map((item) => ({ id: item, label: item })),
    wait_time_for: ["inPerson", "virtual", "both"].map((item) => ({
      id: item,
      label: item,
    })),
    secondary_type: ["walk-in", "family practice", "both"].map((item) => ({
      id: item,
      label: item,
    })),
    visibility: ["local", "region", "country", "global"].map((item) => ({
      id: item,
      label: item,
    })),
    region: JSON.parse(provinceList).map(({ value, text }) => ({
      id: value,
      label: text,
    })),
  };
};

export const useFacilityFields = (facility, values) => {
  const [optionsData, setOptionsData] = useState(null);
  useEffect(() => {
    fetchFacilityDropdownOptions().then(setOptionsData);
  }, []);

  const fields = useMemo(() => {
    if (!optionsData) {
      return null;
    }
    const options = parseFacilityDropdownOptions(optionsData, values);
    return Object.keys(facility).map((key) => {
      if (multiSelectFields.includes(key)) {
        return {
          name: key,
          options: options[key],
          type: "multiSelect",
        };
      } else if (selectFields.includes(key)) {
        return {
          name: key,
          options: options[key],
          type: "select",
        };
      }
      if (textAreaFields.includes(key)) {
        return {
          name: key,
          type: "textArea",
        };
      } else if (toggleFields.includes(key)) {
        return {
          name: key,
          type: "toggle",
        };
      } else {
        return {
          name: key,
          type: "text",
        };
      }
    });
  }, [facility, optionsData, values]);

  return fields;
};

export const useFacility = (id) => {
  const [facility, setFacility] = useState(null);
  useEffect(() => {
    if (id) {
      getClinic(id).then(setFacility);
    } else {
      client
        .get(`tableColumns/facility_details`)
        .then(({ data }) => {
          const facilityDetailsColumns = data.columns.filter(
            (column) => column !== "id"
          );
          const emptyFacility = facilityDetailsColumns.reduce((acc, column) => {
            if (column !== "id" && !HIDDEN_FIELDS_ON_CREATE.includes(column)) {
              acc[column] = "";
            }
            return acc;
          }, {});
          emptyFacility.bundle_id = "";
          emptyFacility.price_variant_id = "";
          emptyFacility.wait_time_for = "";
          emptyFacility.enabled = 1;
          emptyFacility.onboarded = 1;
          emptyFacility.is_legacy = 0;
          emptyFacility.has_location = 1;
          emptyFacility.details_disabled = 0;
          emptyFacility.parent_id = "";
          emptyFacility.parent_pays_subscription = 0;
          emptyFacility.parent_pays_leads = 0;
          setFacility(emptyFacility);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  return facility;
};

const buildTextFields = (textFields, disabledFields) => {
  const { content } = textFields
    .sort(
      (field1, field2) =>
        (textFieldOrderMap[field1.name] || 9999) -
        (textFieldOrderMap[field2.name] || 9999)
    )
    .reduce(
      (acc, field, index) => {
        acc.innerContent.push(
          <Field
            key={field.name}
            component={TextField}
            name={field.name}
            label={field.name}
            disabled={disabledFields.includes(field.name)}
          />
        );
        if ((index + 1) % 4 === 0 || index + 1 === textFields.length) {
          acc.content.push(
            <Row wrap gap="24px" key={index} style={{ maxWidth: "1120px" }}>
              {acc.innerContent}
            </Row>
          );
          acc.innerContent = [];
        }

        return acc;
      },
      { content: [], innerContent: [] }
    );

  return content;
};

const buildSelectFields = (
  selectFields,
  { setSelectedFacilityType, handleSelectChange, disabledFields }
) => {
  return (
    <Row
      weap
      gap="16px"
      align="flex-end"
      style={{ marginTop: "1rem", maxWidth: "1120px" }}
    >
      {selectFields
        .sort(
          (field1, field2) =>
            (selectFieldOrderMap[field1.name] || 9999) -
            (selectFieldOrderMap[field2.name] || 9999)
        )
        .map((field) => (
          <Field
            key={field.name}
            component={Select}
            name={field.name}
            label={field.name}
            style={{ minWidth: "200px" }}
            disabled={disabledFields?.includes(field.name)}
            onChange={(e) => {
              if (field.name === "facility_type") {
                setSelectedFacilityType(e);
              }
              if (handleSelectChange) {
                handleSelectChange(e);
              }
            }}
          >
            {field.options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        ))}
    </Row>
  );
};

const buildTextAreaFields = (textAreaFields, disabledFields) => {
  return (
    <Row wrap gap="32px" style={{ maxWidth: "1120px" }}>
      {textAreaFields.map((field) => (
        <Field
          component={TextField}
          key={field.name}
          name={field.name}
          label={field.name}
          multiline
          minRows={4}
          maxRows={6}
          disabled={disabledFields?.includes(field.name)}
        />
      ))}
    </Row>
  );
};

const buildMultiSelectFields = (multiSelectFields, disabledFields) => {
  return (
    <Row
      wrap
      gap="16px"
      align="flex-end"
      style={{ marginTop: "1rem", maxWidth: "1120px" }}
    >
      {multiSelectFields.map((field) => (
        <Field
          component={MultiSelect}
          style={{ minWidth: "200px" }}
          key={field.name}
          name={field.name}
          label={field.name}
          options={field.options}
          disabled={disabledFields?.includes(field.name)}
        />
      ))}
    </Row>
  );
};

const buildToggleFields = (toggleFields, disabledFields) => {
  return (
    <div
      gap="16px"
      style={{
        maxWidth: "1120px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
      }}
    >
      {toggleFields
        .sort(
          (field1, field2) =>
            (toggleFieldOrderMap[field1.name] || 9999) -
            (toggleFieldOrderMap[field2.name] || 9999)
        )
        .map((field) => (
          <Field
            component={Checkbox}
            key={field.name}
            name={field.name}
            label={field.name}
            tooltip={FIELD_TOOLTIPS[field.name]}
            disabled={disabledFields?.includes(field.name)}
          />
        ))}
    </div>
  );
};

export const buildFacilityFields = (
  fields,
  {
    selectedFacilityType,
    setSelectedFacilityType,
    handleSelectChange,
    disabledFields = [],
  }
) => {
  console.log("disabled fields:", disabledFields);
  const textFields = buildTextFields(
    fields
      .filter((field) => field.type === "text")
      .sort(
        (field1, field2) =>
          (textFieldOrderMap[field1.name] || 9999) -
          (textFieldOrderMap[field2.name] || 9999)
      ),
    disabledFields
  );
  const selectFields = buildSelectFields(
    fields
      .filter((field) => field.type === "select")
      .filter((field) => {
        if (field.name === "secondary_type" || field.name === "wait_time_for") {
          return selectedFacilityType === "clinic";
        }
        return true;
      }),
    { setSelectedFacilityType, handleSelectChange, disabledFields }
  );
  const multiSelectFields = buildMultiSelectFields(
    fields.filter((field) => field.type === "multiSelect"),
    disabledFields
  );
  const textAreaFields = buildTextAreaFields(
    fields.filter((field) => field.type === "textArea"),
    disabledFields
  );

  const toggleFields = buildToggleFields(
    fields.filter((field) => field.type === "toggle"),
    disabledFields
  );

  return {
    textFields,
    selectFields,
    multiSelectFields,
    textAreaFields,
    toggleFields,
  };
};
