import React from "react";
import { useParams } from "react-router-dom";

import { Page } from "pages/Page";
import { EditFacilityForm } from "components/facilities/EditFacilityForm";
import { updateClinic } from "api/clinics";
import { useFacility } from "hooks/facilityDetailsHooks";

export const EditFacilityPage = () => {
  const { facilityId } = useParams();
  const facility = useFacility(facilityId);

  if (!facility) {
    return <div>Loading Clinic...</div>;
  }

  return (
    <Page>
      <EditFacilityForm
        clinic={facility}
        onSubmit={(newValues) =>
          updateClinic(newValues, facility).then(() => window.location.reload())
        }
        title={"Edit Facility"}
      />
    </Page>
  );
};
