import { client } from "./client";
import { isEqual } from "lodash";

export const getClinic = async (id) => {
  const { data } = await client.get(`clinics/${id}`);
  return data.clinic;
};

export const updateClinic = async (values, previousValues) => {
  // only update fields that have changed
  const changedValues = Object.keys(values).reduce((acc, key) => {
    if (!isEqual(previousValues[key], values[key])) {
      acc[key] = values[key];
    }
    return acc;
  }, {});
  try {
    const { data } = await client.post(
      `clinics/${values.id}/update`,
      changedValues
    );
    window.alert("success");
    return data;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error editing clinic: ${err.response.data}`);
  }
};

export const createClinic = async (values) => {
  try {
    const {
      data: { insertId },
    } = await client.post(`clinics/create`, values);
    window.alert(`successfully inserted clinic with id: ${insertId}`);
    return insertId;
  } catch (err) {
    console.error(err.response.data);
    window.alert(`Error creating clinic: ${err.response.data}`);
  }
};

export const getFacilityAudit = async (id) => {
  try {
    const { data } = await client.get(`/clinics/facilityAudit/${id}`);
    const mappedData = data.facilityAudit.map((record) => {
      const { id, ...mappedRecord } = record;
      return mappedRecord;
    });
    return mappedData;
  } catch (err) {
    console.error(err);
    window.alert(`Error getting facility audit: ${err.response.data}`);
  }
};
