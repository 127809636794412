import React, { useCallback } from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { Check } from "phosphor-react";
import classNames from "classnames";

import { Tooltip } from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
    "& a": {
      color: theme.palette.new.blue[1000],
    },
    "& .MuiFormControlLabel-label": {
      color: theme.palette.new.black[200],
      fontSize: "18px",
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "-9px",
    },
    "& .MuiTypography-root": {
      fontFamily: "TTCommons, sans-serif",
      whiteSpace: "normal",
      lineHeight: 1,
    },
  },
  rootWithBoldSelectedLabel: {
    "& .MuiCheckbox-root.Mui-checked + .MuiFormControlLabel-label": {
      fontWeight: 600,
      color: theme.palette.new.black[100],
    },
  },
  checkbox: {
    borderRadius: "4px",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  unchecked: {
    border: `1px solid ${theme.palette.new.surface[300]}`,
  },
  checked: {
    backgroundColor: theme.palette.new.blue[1000],
    "& polyline": {
      strokeWidth: 36,
    },
  },
}));

export const Checkbox = (props) => {
  const {
    label,
    field,
    form,
    checked,
    onChange: onCheckboxChange,
    onClick,
    containerStyle,
    locked,
    onLockClick,
    boldSelectedLabel,
    disabled,
    tooltip,
    ...rest
  } = props;

  console.log("Checkbox", label, "disabled:", disabled);

  const classes = useStyles(props);
  const theme = useTheme();

  const { value, name } = field || { value: {}, name: {} };
  const { touched, errors, setFieldValue } = form || {
    touched: {},
    errors: {},
    setFieldValue: {},
  };

  const onChange = useCallback(
    (event) => {
      if (form) {
        setFieldValue(name, !value);
      } else if (onCheckboxChange) {
        onCheckboxChange(event);
      }
    },
    [value, setFieldValue, name, form]
  );

  return (
    <Tooltip title={tooltip}>
      <FormControl
        onClick={onClick}
        style={containerStyle}
        classes={{
          root: classNames(classes.root, {
            [classes.rootWithBoldSelectedLabel]: boldSelectedLabel,
          }),
        }}
      >
        <FormControlLabel
          disabled={disabled}
          control={
            <MuiCheckbox
              onChange={locked ? onLockClick : onChange}
              color={locked ? theme.palette.grey[200] : "primary"}
              disabled={disabled}
              {...rest}
              checked={field ? !!value : !!checked}
              inputProps={{ "aria-label": "decorative checkbox" }}
              icon={
                <div
                  className={classNames(classes.checkbox, classes.unchecked)}
                />
              }
              checkedIcon={
                <div className={classNames(classes.checkbox, classes.checked)}>
                  <Check color="white" size={16} weight="bold" />
                </div>
              }
            />
          }
          label={label}
        />
        {touched[name] && !!errors[name] && (
          <FormHelperText error>{errors[name]}</FormHelperText>
        )}
      </FormControl>
    </Tooltip>
  );
};
