import React, { useCallback, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MuiTooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: "0 2px 20px -10px rgba(16, 20, 38, 0.2)",
  },
  lightTooltipArrow: {
    color: theme.palette.common.black,
    "&::before": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const Tooltip = (props) => {
  const { title, children, hideArrow, light, ...rest } = props;

  const classes = useStyles();

  const tooltipOpensOnClick = !useMediaQuery(
    "(hover: hover) and (pointer: fine)"
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback((e) => {
    setIsOpen(true);
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const closeTooltip = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openTooltip = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClickAway = useCallback((e) => {
    e.preventDefault();
    closeTooltip();
  }, []);

  const renderedTooltip = (
    <MuiTooltip
      classes={{
        arrow: light ? classes.lightTooltipArrow : undefined,
        tooltip: light ? classes.lightTooltip : undefined,
      }}
      placement="top"
      title={title}
      arrow={!hideArrow}
      onClick={handleClick}
      onClose={closeTooltip}
      onOpen={openTooltip}
      enterTouchDelay={50}
      open={isOpen}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );

  if (!title) {
    return children;
  }

  if (isOpen && tooltipOpensOnClick) {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        {renderedTooltip}
      </ClickAwayListener>
    );
  }

  return renderedTooltip;
};
